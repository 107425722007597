import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { useNavigate } from "react-router-dom";
import {
  HiOutlineSave,
  HiOutlineArrowLeft,
  HiOutlinePlusCircle,
  HiOutlineX,
} from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWRMutation from "swr/mutation";
import { sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";

const FormAdd = () => {
  const [namaPerusahaan, setNamaPerusahaan] = useState("");
  const [namaPic, setNamaPic] = useState("");
  const [nomorHp, setNomorHp] = useState(0);
  const [koordinat, setKoordinat] = useState([]);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const navigate = useNavigate();

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/admin/perusahaan`,
    sendRequestPostWithToken
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const addKoordinat = (e) => {
    e.preventDefault();

    setKoordinat((koordinat) => [
      ...koordinat,
      {
        latitude: latitude,
        longitude: longitude,
      },
    ]);

    setLatitude(0);
    setLongitude(0);
  };

  const delKoordinat = (index) => {
    setKoordinat((koordinat) => {
      return koordinat.filter((_, i) => i !== index);
    });
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      if (koordinat.length > 0) {
        const result = await trigger({
          namaPerusahaan: namaPerusahaan,
          namaPic: namaPic,
          nomorHp: nomorHp,
          koordinat: koordinat,
        });

        Swal.fire({
          icon: "success",
          title: "Sukses!",
          text: result.message,
          confirmButtonText: "Oke",
          willClose: () => {
            navigate(-1);
          },
        });
      } else {
        Swal.fire(
          "Error!",
          "Silahkan tambahkan koordinat terlebih dahulu!",
          "warning"
        );
      }
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating) return <Loading />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans text-lg font-bold text-indigo-700">
          Tambah Perusahaan Baru
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Penambahan data perusahaan, isi informasi data dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6">
        <form
          onSubmit={simpan}
          className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">Detail Perusahaan</h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Nama perusahaan, pic dan koordinat
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="NAMA PERUSAHAAN"
                name="namaPerusahaan"
                val={namaPerusahaan}
                set={setNamaPerusahaan}
                placeholder="Masukkan nama perusahaan"
              />
            </div>

            <div className="sm:col-span-3">
              <InputText
                label="NAMA PIC"
                name="namaPic"
                val={namaPic}
                set={setNamaPic}
                placeholder="Masukkan nama pic"
              />
              <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
                Nama pic yang bertanggung jawab
              </p>
            </div>
            <div className="col-start-2 sm:col-span-3">
              <InputText
                label="NOMOR HP"
                name="nomorHp"
                val={nomorHp}
                set={setNomorHp}
                placeholder="Masukkan nomor hp"
              />
              <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
                Masukan nomor Hp yang aktif
              </p>
            </div>
            <div className="sm:col-start-1 sm:col-span-2">
              <InputText
                label="LATITUDE"
                name="latitude"
                val={latitude}
                set={setLatitude}
                placeholder="0"
              />
              <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
                Ketik latitude (koordinat)
              </p>
            </div>
            <div className="sm:col-span-2">
              <InputText
                label="LONGITUDE"
                name="longitude"
                val={longitude}
                set={setLongitude}
                placeholder="0"
              />
              <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
                Ketik longitude (koordinat)
              </p>
            </div>
            <div className="sm:col-span-1">
              <button
                type="button"
                onClick={addKoordinat}
                className="text-white mt-7 bg-[#14b8a6] hover:bg-[#14b8a6]/90 active::bg-[#14b8a6]/50  focus:outline-none focus:ring-[#14b8a6]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              >
                <span className="mr-2">
                  <HiOutlinePlusCircle size={18} />
                </span>
                <span>Add</span>
              </button>
            </div>
            <div className="sm:col-start-1 sm:col-span-4">
              <h2 className="font-bold font-sans text-black mb-4">
                Koordinat Perusahaan
              </h2>

              {koordinat.length > 0 ? (
                koordinat.map((value, index) => (
                  <div
                    key={index}
                    className="flex flex-row mb-3 gap-3 rounded-lg shadow p-4 font-sans text-sm border-l-4 border-blue-600 bg-gray-50 hover:bg-gray-200"
                  >
                    <div className="flex-1">
                      <p>
                        <span className="font-bold">Latitude:</span>{" "}
                        {value.latitude}
                      </p>
                      <p>
                        <span className="font-bold">Longitude:</span>{" "}
                        {value.longitude}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => delKoordinat(index)}
                        value={index}
                        className="button rounded-full bg-rose-400 hover:bg-rose-300 text-gray-50 shadow"
                      >
                        <HiOutlineX size={18} />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-sm font-sans text-gray-400">
                  Silahkan tambahkan koordinat...
                </p>
              )}
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave size={18} />
              </span>
              <span>Simpan Perusahaan</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAdd;
