import Root from "./Root";
import Login from "../pages/Login";

import Home from "../pages/Home";

import User from "../pages/User";
import TableUser from "../pages/User/Table";
import FormAddUser from "../pages/User/FormAdd";
import FormEditUser from "../pages/User/FormEdit";
import FormPassword from "../pages/User/FormPassword";

import Pendaftaran from "../pages/Pendaftaran";
import TablePendaftaran from "../pages/Pendaftaran/Table";

import Perusahaan from "../pages/Perusahaan";
import TablePerusahaan from "../pages/Perusahaan/Table";
import FormAddPerusahaan from "../pages/Perusahaan/FormAdd";
import FormEditPerusahaan from "../pages/Perusahaan/FormEdit";
import DetailPendaftaran from "../pages/Pendaftaran/DetailPendaftaran";

import Jabatan from "../pages/Jabatan";
import TableJabatan from "../pages/Jabatan/Table";
import FormAddJabatan from "../pages/Jabatan/FormAdd";
import FormEditJabatan from "../pages/Jabatan/FormEdit";

import Absensi from "../pages/Absensi";
import TableAbsensi from "../pages/Absensi/Table";

import Karyawan from "../pages/Karyawan";
import TableKaryawan from "../pages/Karyawan/Table";
import FormEditKaryawan from "../pages/Karyawan/FormEdit";

import ErrorPage from "../pages/ErrorPage";

export const Paths = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "user",
        element: <User />,
        breadcrumb: "User",
        children: [
          { index: true, element: <TableUser />, breadcrumb: "User" },
          {
            path: "add",
            element: <FormAddUser />,
            breadcrumb: "Tambah User",
          },
          {
            path: "edit/:id",
            element: <FormEditUser />,
            breadcrumb: "Edit User",
          },
          {
            path: "password/:id",
            element: <FormPassword />,
            breadcrumb: "Edit Password",
          },
        ],
      },
      {
        path: "pendaftaran",
        element: <Pendaftaran />,
        breadcrumb: "Pendaftaran",
        children: [
          {
            index: true,
            element: <TablePendaftaran />,
            breadcrumb: "Pendaftaran",
          },

          {
            path: "detail/:id",
            element: <DetailPendaftaran />,
            breadcrumb: "Detail Pendaftaran",
          },
        ],
      },

      {
        path: "perusahaan",
        element: <Perusahaan />,
        breadcrumb: "Perusahaan",
        children: [
          {
            index: true,
            element: <TablePerusahaan />,
            breadcrumb: "Perusahaan",
          },
          {
            path: "add",
            element: <FormAddPerusahaan />,
            breadcrumb: "Tambah Perusahaan",
          },
          {
            path: "edit/:id",
            element: <FormEditPerusahaan />,
            breadcrumb: "Edit Perusahaan",
          },
        ],
      },
      {
        path: "jabatan",
        element: <Jabatan />,
        breadcrumb: "Jabatan",
        children: [
          {
            index: true,
            element: <TableJabatan />,
            breadcrumb: "Jabatan",
          },
          {
            path: "add",
            element: <FormAddJabatan />,
            breadcrumb: "Tambah Jabatan",
          },
          {
            path: "edit/:id",
            element: <FormEditJabatan />,
            breadcrumb: "Edit Jabatan",
          },
        ],
      },
      {
        path: "absensi",
        element: <Absensi />,
        breadcrumb: "Absensi",
        children: [
          {
            index: true,
            element: <TableAbsensi />,
            breadcrumb: "Absensi",
          },
          {
            path: "add",
            element: <FormAddJabatan />,
            breadcrumb: "Tambah Jabatan",
          },
          {
            path: ":id",
            element: <FormEditJabatan />,
            breadcrumb: "Edit Jabatan",
          },
        ],
      },
      {
        path: "karyawan",
        element: <Karyawan />,
        breadcrumb: "Karyawan",
        children: [
          {
            index: true,
            element: <TableKaryawan />,
            breadcrumb: "Kayawan",
          },
          {
            path: "add",
            element: <FormAddJabatan />,
            breadcrumb: "Tambah Jabatan",
          },
          {
            path: "edit/:id",
            element: <FormEditKaryawan />,
            breadcrumb: "Edit Karyawan",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
];
