import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Absensi = () => {
  return (
    <div>
      <Header titleHead="Absensi" icon="solar:book-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default Absensi;
