import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Icon } from "@iconify-icon/react";
import { API_URL, API_UPLOAD } from "../../config/index";
import ModalImage from "react-modal-image";
import { formatDate } from "../../function/DateFormat";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestDelete, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TableGrid from "../../components/TableGrid";
import Badge from "../../components/Badge";

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemPage, setItemPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("nik");
  const [fieldQuery, setFieldQuery] = useState("nik");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/admin/user`,
    sendRequestDelete
  );

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL}/admin/karyawan?field=${fieldQuery}&keyword=${keywordQuery}&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  // console.log(data);

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await trigger({ id: id });

          mutate();

          Swal.fire("Deleted!", result.message, "success");
        } catch (e) {
          Swal.fire("Deleted!", e.info.message, "error");
        }
      }
    });
  };

  const changePage = ({ selected }) => {
    setItemPage(selected);
    setPage(selected + 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("nik");
    setFieldQuery("nik");
    setLimit(10);
  };

  const _cekStatusKelengkapan = ({
    statusAlamat,
    statusRekening,
    statusAsuransi,
  }) => {
    if (
      statusAlamat === "Y" &&
      statusRekening === "Y" &&
      statusAsuransi === "Y"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const _cekStatusKelengkapanPersen = ({
    statusAlamat,
    statusRekening,
    statusAsuransi,
  }) => {
    let total = 0;

    if (statusAlamat === "Y") {
      total = total + 1;
    }

    if (statusRekening === "Y") {
      total = total + 1;
    }

    if (statusAsuransi === "Y") {
      total = total + 1;
    }

    const persen = Math.ceil((total / 3) * 100);

    return persen;
  };

  if (isLoading || isMutating) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <TableGrid
      search={{
        itemField: [
          {
            title: "Nama Lengkap",
            value: "namaLengkap",
          },
          {
            title: "NIK",
            value: "nik",
          },
        ],
        setField: setField,
        field: field,
        keyword: keyword,
        setKeyword: setKeyword,
        submit: searchData,
      }}
      toolbox={{
        reset: resetPage,
      }}
      columns={[
        "#",
        "FOTO",
        "NAMA/NIK",
        "TGL.LAHIR",
        "USIA",
        "L/P",
        "JABATAN/PERUSAHAAN",
        "STATUS",
        "DATA",
        "CREATED AT",
        "UPDATED AT",
        "EDIT",
        "HAPUS",
      ]}
      data={data.docs.map((val) => {
        return [
          val._id,
          <ModalImage
            small={`${API_UPLOAD}/tadmobile/account/resize/x256/${val.fotoUrl}`}
            large={`${API_UPLOAD}/tadmobile/account/real/${val.fotoUrl}`}
            alt={val.namaUser}
            hideZoom={true}
            showRotate={true}
            className="w-[35px] h-[35px] rounded-lg drop-shadow hover:drop-shadow-lg"
          />,
          <div className="font-sans">
            <span className="font-bold">{val.namaLengkap}</span>
            <br />
            <span className="text-[11px] text-gray-400">{val.nik}</span>
          </div>,
          <div className="font-sans">
            <span className="text-[10px] text-gray-400">{val.tmpLahir}</span>
            <br />
            <span>{val.tglLahir}</span>
          </div>,
          val.usia,
          <Badge
            text={val.jenisKel}
            tipe={val.jenisKel === "L" ? "success" : "warning"}
          />,
          <div className="font-sans">
            <span>{val.jabatan}</span>
            <br />
            <span className="text-[10px] text-gray-400">
              {val.namaPerusahaan}
            </span>
          </div>,
          <Badge
            text={val.statusUser}
            tipe={val.statusUser === "Y" ? "primary" : "warning"}
          />,
          <div>
            <Badge
              text={
                _cekStatusKelengkapan({
                  statusAlamat: val.alamat.statusAlamat,
                  statusRekening: val.rekening.statusRekening,
                  statusAsuransi: val.asuransi.statusAsuransi,
                })
                  ? "SL"
                  : "BL"
              }
              tipe={
                _cekStatusKelengkapan({
                  statusAlamat: val.alamat.statusAlamat,
                  statusRekening: val.rekening.statusRekening,
                  statusAsuransi: val.asuransi.statusAsuransi,
                })
                  ? "success"
                  : "danger"
              }
            />
            <div className="w-[60px] bg-gray-200 rounded-full mt-1">
              <div
                className={
                  _cekStatusKelengkapanPersen({
                    statusAlamat: val.alamat.statusAlamat,
                    statusRekening: val.rekening.statusRekening,
                    statusAsuransi: val.asuransi.statusAsuransi,
                  }) > 0
                    ? `bg-blue-600 text-xs font-normal text-blue-100 text-center p-0.5 leading-none rounded-full`
                    : `text-xs font-normal text-blue-100 text-center p-0.5 leading-none rounded-full`
                }
                style={{
                  width:
                    _cekStatusKelengkapanPersen({
                      statusAlamat: val.alamat.statusAlamat,
                      statusRekening: val.rekening.statusRekening,
                      statusAsuransi: val.asuransi.statusAsuransi,
                    }) > 0
                      ? `${_cekStatusKelengkapanPersen({
                          statusAlamat: val.alamat.statusAlamat,
                          statusRekening: val.rekening.statusRekening,
                          statusAsuransi: val.asuransi.statusAsuransi,
                        })}%`
                      : "0",
                }}
              >
                {_cekStatusKelengkapanPersen({
                  statusAlamat: val.alamat.statusAlamat,
                  statusRekening: val.rekening.statusRekening,
                  statusAsuransi: val.asuransi.statusAsuransi,
                })}
                %
              </div>
            </div>
          </div>,
          formatDate(val.createdAt),
          formatDate(val.updatedAt),
          <Link to={`edit/${val._id}`}>
            <Icon
              icon="solar:pen-new-round-bold-duotone"
              style={{ fontSize: "21px", color: "#65a30d" }}
            />
          </Link>,
          <button type="button" onClick={() => deleteData(val.userId)}>
            <Icon
              icon="solar:trash-bin-trash-bold-duotone"
              style={{ fontSize: "21px", color: "#e11d48" }}
            />
          </button>,
        ];
      })}
      totalDocs={data.totalDocs}
      totalPages={data.totalPages}
      page={itemPage}
      limit={limit}
      setLimit={setLimit}
      changePage={changePage}
    />
  );
};

export default Table;
