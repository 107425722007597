import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineSave, HiOutlineArrowLeft } from "react-icons/hi";

import useSWR from "swr";
import { sendRequestGet, sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";

import InputText from "../../components/Form2/InputText";

import useSWRMutation from "swr/mutation";

import { API_URL } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import Badge from "../../components/Badge";

const Detail = () => {
  const { id } = useParams();

  const [nomorKaryawan, setNomorKaryawan] = useState("");

  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    `${API_URL}/admin/pendaftaran/${id}`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/admin/pendaftaran-konfirmasi/${id}`,
    sendRequestPostWithToken
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        nomorKaryawan: nomorKaryawan,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Detail Pendaftaran
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Konfirmasi pendaftaran akun karyawan baru.
        </p>
      </div>
      <div className="grid grid-cols-6 gap-y-4 py-6">
        <div className="col-start-2 col-span-4 shadow-lg mx-4 bg-white rounded-xl">
          <div className="flex flex-row items-center border-b border-gray-300 py-4 px-4">
            <div className="flex-1">
              <h2 className="font-sans font-bold text-lg">
                Detail Konfirmasi Pendaftaran
              </h2>
              <p className="font-sans font-normal text-sm text-gray-400">
                Konfirmasi pendaftaran akun karyawan
              </p>
            </div>
            <div>
              <Badge
                text={data.statusDaftar === "Y" ? "TERKONFIRMASI" : "PENDING"}
                tipe={data.statusDaftar === "Y" ? "success" : "danger"}
              />
            </div>
          </div>
          <dl class="w-full text-gray-900 divide-y divide-gray-200 divide-dashed px-4 mt-5 mb-8">
            <div class="flex flex-col pb-2 pt-1 hover:bg-slate-100">
              <dt class="mb-1 text-gray-500 font-sans text-sm">
                Nomor Induk Kependudukan
              </dt>
              <dd class="font-sans text-sm font-semibold">{data.nik}</dd>
            </div>
            <div class="flex flex-col pb-2 pt-1 hover:bg-slate-100">
              <dt class="mb-1 text-gray-500 font-sans text-sm">Nama Lengkap</dt>
              <dd class="font-sans text-sm font-semibold">
                {data.namaLengkap} ({data.jenisKel})
              </dd>
            </div>
            <div class="flex flex-col pb-2 pt-1 hover:bg-slate-100">
              <dt class="mb-1 text-gray-500 font-sans text-sm">
                Tempat, Tanggal Lahir
              </dt>
              <dd class="font-sans text-sm font-semibold">
                {data.tmpLahir}, {data.tglLahir}
              </dd>
            </div>
            <div class="flex flex-col pb-2 pt-1 hover:bg-slate-100">
              <dt class="mb-1 text-gray-500 font-sans text-sm">
                Nomor Handphone
              </dt>
              <dd class="font-sans text-sm font-semibold">{data.nomorHp}</dd>
            </div>
            <div class="flex flex-col pb-2 pt-1 hover:bg-slate-100">
              <dt class="mb-1 text-gray-500 font-sans text-sm">Username</dt>
              <dd class="font-sans text-sm font-semibold">{data.username}</dd>
            </div>
            <div class="flex flex-col pb-2 pt-1 hover:bg-slate-100">
              <dt class="mb-1 text-gray-500 font-sans text-sm">
                Perusahaan / Jabatan
              </dt>
              <dd class="font-sans text-sm font-semibold">
                {data.namaPerusahaan} / {data.jabatan}
              </dd>
            </div>
          </dl>
        </div>

        {data.statusDaftar === "N" && (
          <div className="col-start-2 col-span-4 shadow-lg mx-4 bg-white p-4 rounded-xl">
            <form onSubmit={simpan} className="grid grid-cols-1">
              <div className="col-span-1">
                <InputText
                  label="Nomor Karyawan"
                  name="nomorKaryawan"
                  val={nomorKaryawan}
                  set={setNomorKaryawan}
                  placeholder="Masukkan nomor karyawan"
                />
              </div>
              <div className="mt-10 flex items-center gap-x-2">
                <button
                  type="button"
                  className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                  onClick={() => navigate(-1)}
                >
                  <span className="mr-2">
                    <HiOutlineArrowLeft size={18} />
                  </span>
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  disabled={isMutating}
                  className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                >
                  <span className="mr-2">
                    <HiOutlineSave size={18} />
                  </span>
                  <span>Konfirmasi</span>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Detail;
