import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Karyawan = () => {
  return (
    <div>
      <Header titleHead="Karyawan" icon="solar:user-id-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default Karyawan;
