import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const User = () => {
  return (
    <div>
      <Header titleHead="User" icon="solar:users-group-rounded-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default User;
