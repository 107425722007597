import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify-icon/react";

import tad from "../../assets/images/logo-tad.png";

const Sidebar = () => {
  const menuList = [
    {
      title: "Navigation",
    },
    {
      title: "Dashboard",
      path: "/",
      icon: (
        <Icon
          icon="solar:home-smile-bold-duotone"
          style={{ fontSize: "24px", color: "#f97316" }}
        />
      ),
    },
    {
      title: "Master",
    },
    {
      title: "Perusahaan",
      path: "/perusahaan",
      icon: (
        <Icon
          icon="solar:buildings-2-bold-duotone"
          style={{ fontSize: "24px", color: "#f97316" }}
        />
      ),
    },
    {
      title: "Jabatan",
      path: "/jabatan",
      icon: (
        <Icon
          icon="solar:chair-2-bold-duotone"
          style={{ fontSize: "24px", color: "#f97316" }}
        />
      ),
    },
    {
      title: "User",
      path: "/user",
      icon: (
        <Icon
          icon="solar:users-group-rounded-bold-duotone"
          style={{ fontSize: "24px", color: "#f97316" }}
        />
      ),
    },
    {
      title: "Karyawan",
    },
    {
      title: "Register Karyawan",
      path: "/pendaftaran",
      icon: (
        <Icon
          icon="solar:clipboard-bold-duotone"
          style={{ fontSize: "24px", color: "#f97316" }}
        />
      ),
    },
    {
      title: "Karyawan",
      path: "/karyawan",
      icon: (
        <Icon
          icon="solar:user-id-bold-duotone"
          style={{ fontSize: "24px", color: "#f97316" }}
        />
      ),
    },
    {
      title: "Absensi",
      path: "/absensi",
      icon: (
        <Icon
          icon="solar:book-bold-duotone"
          style={{ fontSize: "24px", color: "#f97316" }}
        />
      ),
    },
  ];

  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased fixed z-20">
      <div className="fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
        <div className="flex items-center justify-center h-24">
          <img src={tad} className="w-[70px]" alt="logo" />
          <div className="font-sans font-bold text-sm text-dark">
            Trans Arjuna Delapan
          </div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-1">
            {menuList.map((menu, index) => {
              if (menu.path) {
                return (
                  <li key={`menu-${index}`}>
                    <NavLink
                      to={menu.path}
                      className={({ isActive }) =>
                        isActive
                          ? "relative flex flex-row items-center ml-1 mr-1 h-11 focus:outline-none text-orange-600 bg-orange-100 pr-6 rounded-lg"
                          : "relative flex flex-row items-center ml-1 mr-1 h-11 focus:outline-none text-black hover:bg-gray-100 hover:text-gray-800 transition ease-in-out delay-75 duration-300 border-transparent rounded-lg"
                      }
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        {menu.icon}
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        {menu.title}
                      </span>
                    </NavLink>
                  </li>
                );
              } else {
                return (
                  <li key={`list-${index}`} className="px-5">
                    <div className="flex flex-row items-center h-8">
                      <div className="text-sm font-bold tracking-wide text-dark-500">
                        {menu.title}
                      </div>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
