import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Icon } from "@iconify-icon/react";
import { API_URL } from "../../config/index";
import ModalImage from "react-modal-image";
import { formatDate } from "../../function/DateFormat";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {
  sendRequestDelete,
  sendRequestGet,
  sendRequestGetDownload,
} from "../../swr";
import Loading from "../Loading";
import TableGrid from "../../components/TableGrid";
import Badge from "../../components/Badge";
import Modal from "../../components/Modal";

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemPage, setItemPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("namaUser");
  const [fieldQuery, setFieldQuery] = useState("namaUser");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");

  const [tglFilter, setTglFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const [tabFilter, setTabFilter] = useState("");

  const [fPerusahaan, setFPerusahaan] = useState([]);
  const [fPerusahaanQuery, setFPerusahaanQuery] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/admin/absen`,
    sendRequestDelete
  );

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL}/admin/absen?field=${fieldQuery}&keyword=${keywordQuery}&perusahaan=${fPerusahaanQuery}&jenisAbsen=${tabFilter}&tglMulai=${tglMulai}&tglAkhir=${tglAkhir}&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  const { trigger: triggerDownload } = useSWRMutation(
    `${API_URL}/admin/laporan/laporan-absen?field=${fieldQuery}&keyword=${keywordQuery}&perusahaan=${fPerusahaanQuery}&jenisAbsen=${tabFilter}&tglMulai=${tglMulai}&tglAkhir=${tglAkhir}`,
    sendRequestGetDownload
  );

  const { data: dataPerusahaan, isLoading: isLoadingPerusahaan } = useSWR(
    `${API_URL}/admin/perusahaan-select`,
    sendRequestGet
  );
  // console.log(dataPerusahaan);

  useEffect(() => {
    // get refresh token
    checkToken();

    setFPerusahaanQuery(fPerusahaan.join(","));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fPerusahaan]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await trigger({ id: id });

          mutate();

          Swal.fire("Deleted!", result.message, "success");
        } catch (e) {
          Swal.fire("Deleted!", e.info.message, "error");
        }
      }
    });
  };

  const _handleDownloadPdf = async () => {
    await Swal.fire({
      title: "Proses donwload...",
      didOpen: async () => {
        Swal.showLoading();

        const result = await triggerDownload();
        console.log(result);
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "laporan-absensi-karyawan.pdf"); // nama file dan extension sesuaikan dengan file yang di download
        document.body.appendChild(link);
        link.click();

        Swal.fire(
          "Download Success!",
          "Berhasil mendownload laporan pdf",
          "success"
        );
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        Swal.close();
      }
    });
  };

  const changePage = ({ selected }) => {
    setItemPage(selected);
    setPage(selected + 1);
  };

  const searchData = (e) => {
    e.preventDefault();

    setPage(0);
    setItemPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);
    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    } else {
      setTglFilter({
        startDate: null,
        endDate: null,
      });
      setTglMulai("");
      setTglAkhir("");
    }

    mutate();
  };

  const resetPage = (e) => {
    e.preventDefault();

    setPage(0);
    setItemPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("namaUser");
    setFieldQuery("namaUser");
    setFPerusahaan([]);
    setFPerusahaanQuery("");
    setTglMulai("");
    setTglAkhir("");
    setTglFilter({
      startDate: null,
      endDate: null,
    });
    setLimit(10);
    mutate();
  };

  const _handleOnChange = (e) => {
    if (e.target.checked === true) {
      setFPerusahaan([...fPerusahaan, e.target.value]);
    } else {
      const arrayData = [...fPerusahaan];
      const index = arrayData.indexOf(e.target.value);
      if (index > -1) {
        arrayData.splice(index, 1);
        setFPerusahaan(arrayData);
      }
    }
  };

  const _handleFilter = (val) => {
    const arrayData = [...fPerusahaan];
    const index = arrayData.indexOf(val);
    if (index > -1) {
      arrayData.splice(index, 1);
      setFPerusahaan(arrayData);
    }
  };

  const _handleNameFilter = (id) => {
    const resJns = dataPerusahaan.find((item) => item.value === id);

    return resJns?.name;
  };

  if (isLoading || isMutating || isLoadingPerusahaan) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <TableGrid
        search={{
          itemField: [
            {
              title: "Nama User",
              value: "namaUser",
            },
          ],
          datePicker: {
            setTglFilter: setTglFilter,
            tglFilter: tglFilter,
          },
          setField: setField,
          field: field,
          keyword: keyword,
          setKeyword: setKeyword,
          submit: searchData,
        }}
        tabFilter={{
          itemTab: [
            {
              title: "ALL",
              value: "",
            },
            {
              title: "ABSEN MASUK",
              value: "M",
            },
            {
              title: "ABSEN PULANG",
              value: "P",
            },
          ],
          setTab: setTabFilter,
          tabActive: tabFilter,
        }}
        toolbox={{
          reset: resetPage,
          download: _handleDownloadPdf,
        }}
        modal={{
          setOpenModal: setOpenModal,
          openModal: openModal,
          data: fPerusahaan,
          nameFilter: _handleNameFilter,
          handle: _handleFilter,
        }}
        columns={[
          "#",
          "FOTO",
          "NAMA",
          "J.ABSEN",
          "TGL.ABSEN",
          "PERUSAHAAN",
          "KOORDINAT",
          "CREATED AT",
          "UPDATED AT",
          "HAPUS",
        ]}
        data={data.docs.map((val) => {
          return [
            val._id,
            <ModalImage
              small={val.fotoAbsen}
              large={val.fotoReal}
              alt={val.namaUser}
              hideZoom={true}
              showRotate={true}
              className="w-[35px] h-[35px] rounded-lg drop-shadow hover:drop-shadow-lg"
            />,
            <div className="font-sans font-bold">
              {val.namaUser}
              <br />
              <span className="text-[10px] text-gray-400 font-normal">
                {val._id}
              </span>
            </div>,
            <Badge
              text={val.jenisAbsen}
              tipe={val.jenisAbsen === "M" ? "success" : "danger"}
            />,
            val.tglAbsen,
            val.namaPerusahaan,
            <a
              href={`https://www.google.com/maps/place/${val.latitude},${val.longitude}`}
              target="_blank"
              className="inline-flex flex-row items-center gap-1"
              rel="noreferrer"
            >
              <Icon
                icon="solar:point-on-map-bold-duotone"
                style={{ fontSize: "21px", color: "#2563eb" }}
              />
              <span className="text-[11px] text-gray-400 hover:text-orange-600">
                OpenMap
              </span>
            </a>,
            formatDate(val.createdAt),
            formatDate(val.updatedAt),
            <button type="button" onClick={() => deleteData(val._id)}>
              <Icon
                icon="solar:trash-bin-trash-bold-duotone"
                style={{ fontSize: "21px", color: "#e11d48" }}
              />
            </button>,
          ];
        })}
        totalDocs={data.totalDocs}
        totalPages={data.totalPages}
        page={itemPage}
        limit={limit}
        setLimit={setLimit}
        changePage={changePage}
      />
      <Modal
        title="Filter Absen"
        active={openModal}
        setClose={setOpenModal}
        content={
          <>
            <h2 className="font-sans font-bold text-sm mb-3 border-b px-2 pb-1">
              Filter Perusahaan
            </h2>
            <ul className="mb-3">
              {dataPerusahaan.map((p, index) => (
                <li
                  key={`li-${index}`}
                  className="px-1 py-2 border-b border-dashed font-sans text-xs hover:bg-gray-100"
                >
                  <div className="flex flex-row items-center gap-3">
                    <input
                      id={`cb-${p.value}`}
                      type="checkbox"
                      value={p.value}
                      checked={fPerusahaan.includes(p.value) ? true : false}
                      onChange={(e) => _handleOnChange(e)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor={`cb-${p.value}`}
                      className="flex-1 font-sans text-xs cursor-pointer"
                    >
                      {p.name}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </>
        }
      />
    </>
  );
};

export default Table;
