/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Icon } from "@iconify-icon/react";
import { API_UPLOAD, API_URL } from "../../config/index";

import { formatDate } from "../../function/DateFormat";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import ModalImage from "react-modal-image";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestDelete, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import Badge from "../../components/Badge";
import TableGrid from "../../components/TableGrid";

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemPage, setItemPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("namaUser");
  const [fieldQuery, setFieldQuery] = useState("namaUser");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const [tabFilter, setTabFilter] = useState("");

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/admin/user`,
    sendRequestDelete
  );

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL}/admin/user?field=${fieldQuery}&keyword=${keywordQuery}&status=${tabFilter}&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  // console.log(data);

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await trigger({ id: id });

          mutate();

          Swal.fire("Deleted!", result.message, "success");
        } catch (e) {
          Swal.fire("Deleted!", e.info.message, "error");
        }
      }
    });
  };

  const changePage = ({ selected }) => {
    setItemPage(selected);
    setPage(selected + 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("namaUser");
    setFieldQuery("namaUser");
    setLimit(10);
  };

  if (isLoading || isMutating) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <TableGrid
      search={{
        itemField: [
          {
            title: "Nama User",
            value: "namaUser",
          },
          {
            title: "Username",
            value: "username",
          },
          {
            title: "Level",
            value: "level",
          },
        ],
        setField: setField,
        field: field,
        keyword: keyword,
        setKeyword: setKeyword,
        submit: searchData,
      }}
      tabFilter={{
        itemTab: [
          {
            title: "ALL",
            value: "",
          },
          {
            title: "AKTIF",
            value: "Y",
          },
          {
            title: "TIDAK AKTIF",
            value: "N",
          },
        ],
        setTab: setTabFilter,
        tabActive: tabFilter,
      }}
      toolbox={{
        reset: resetPage,
        add: "add",
      }}
      columns={[
        "#",
        "FOTO",
        "NAMA USER",
        "USERNAME",
        "LEVEL",
        "STATUS",
        "CREATED AT",
        "UPDATED AT",
        "CHGPW",
        "EDIT",
        "HAPUS",
      ]}
      data={data.docs.map((val) => {
        return [
          val._id,
          <ModalImage
            small={`${API_UPLOAD}/tadmobile/account/resize/x256/${val.fotoUrl}`}
            large={`${API_UPLOAD}/tadmobile/account/real/${val.fotoUrl}`}
            alt={val.namaUser}
            hideZoom={true}
            showRotate={true}
            className="w-[32px] h-[32px] rounded-lg drop-shadow hover:drop-shadow-lg"
          />,
          val.namaUser,
          val.username,
          <Badge
            text={val.level}
            tipe={
              val.level === "admin"
                ? "danger"
                : val.level === "karyawan"
                ? "success"
                : "warning"
            }
          />,
          <Badge
            text={val.statusUser === "Y" ? "AKTIF" : "TIDAK AKTIF"}
            tipe={val.statusUser === "Y" ? "primary" : "warning"}
          />,
          formatDate(val.createdAt),
          formatDate(val.updatedAt),
          <Link to={`password/${val._id}`}>
            <Icon
              icon="solar:key-square-2-bold-duotone"
              style={{ fontSize: "21px", color: "#ea580c" }}
            />
          </Link>,
          <Link to={`edit/${val._id}`}>
            <Icon
              icon="solar:pen-new-round-bold-duotone"
              style={{ fontSize: "21px", color: "#65a30d" }}
            />
          </Link>,
          <button type="button" onClick={() => deleteData(val._id)}>
            <Icon
              icon="solar:trash-bin-trash-bold-duotone"
              style={{ fontSize: "21px", color: "#e11d48" }}
            />
          </button>,
        ];
      })}
      totalDocs={data.totalDocs}
      totalPages={data.totalPages}
      page={itemPage}
      limit={limit}
      setLimit={setLimit}
      changePage={changePage}
    />
  );
};

export default Table;
