import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Jabatan = () => {
  return (
    <div>
      <Header titleHead="Jabatan" icon="solar:chair-2-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default Jabatan;
