import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import jwtDecode from "jwt-decode";
import { API_URL } from "../../config/index";
import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import {
  HiOutlineUserGroup,
  HiOutlineOfficeBuilding,
  HiSortAscending,
  HiSortDescending,
} from "react-icons/hi";
import Loading from "../Loading";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const Home = () => {
  const navigate = useNavigate();
  const [namaUser, setNamaUser] = useState("");

  const { data, error, isLoading } = useSWR(
    `${API_URL}/admin/dashboard/total-data`,
    sendRequestGet
  );

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(data);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      } else {
        setNamaUser(decoded.namaUser);
      }
    } else {
      navigate("/login");
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <Header titleHead="Home" icon="solar:graph-new-up-bold-duotone" />
      <div className="p-4 ">
        <p className="font-sans text-sm mb-10">
          Selamat Datang, <strong>{namaUser}</strong>!
        </p>
        {/* grid menu */}
        <div className="grid grid-cols-4 mt-2 gap-2 h-32">
          <div className="grid grid-cols-1 gap-3 bg-blue-100 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiOutlineUserGroup size={50} />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-gray-400 text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data.totalKaryawan}
                  </h1>
                  <p className="font-poppins-regular text-xl text-gray-400 lg:text-right">
                    KARYAWAN
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-3 bg-orange-100 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiOutlineOfficeBuilding size={50} />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-gray-400 text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data.totalPerusahaan}
                  </h1>
                  <p className="font-poppins-regular text-xl text-gray-400 lg:text-right">
                    PERUSAHAAN
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-3 bg-emerald-100 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiSortAscending size={50} />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-gray-400 text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data.totalAbsenMasuk}
                  </h1>
                  <p className="font-poppins-regular text-xl text-gray-400 lg:text-right">
                    ABSEN MASUK
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-3 bg-rose-100 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiSortDescending size={50} />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-gray-400 text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data.totalAbsenPulang}
                  </h1>
                  <p className="font-poppins-regular text-xl text-gray-400 lg:text-right">
                    ABSEN PULANG
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* end grid menu */}
        </div>
      </div>
    </div>
  );
};

export default Home;
