import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineSave,
  HiOutlineArrowLeft,
  HiCheckCircle,
  HiXCircle,
} from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestPut, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import Select from "../../components/Form2/Select";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import InputTgl from "../../components/Form2/InputTgl";

const FormEdit = () => {
  const { id } = useParams();

  const [nik, setNik] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [nomorKaryawan, setNomorKaryawan] = useState("");
  const [tmpLahir, setTmpLahir] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [jenisKel, setJenisKel] = useState("");
  const [nomorHp, setNomorHp] = useState("");
  const [statusAlamat, setStatusAlamat] = useState("N");
  const [statusRekening, setStatusRekening] = useState("N");
  const [statusAsuransi, setStatusAsuransi] = useState("N");

  const [perusahaanId, setPerusahaanId] = useState("");
  const [jabatanId, setJabatanId] = useState("");

  const itemTipe = [
    {
      name: "Laki-Laki",
      value: "L",
    },
    {
      name: "Perempuan",
      value: "P",
    },
  ];

  const navigate = useNavigate();

  const { error, isLoading } = useSWR(
    `${API_URL}/admin/karyawan/${id}`,
    sendRequestGet,
    {
      onSuccess: (data) => {
        setNik(data.nik);
        setNamaLengkap(data.namaLengkap);
        setNomorKaryawan(data.nomorKaryawan);
        setTmpLahir(data.tmpLahir);
        setTglLahir(data.tglLahir);
        setJenisKel(data.jenisKel);
        setNomorHp(data.nomorHp);
        setPerusahaanId(data.perusahaanId._id);
        setJabatanId(data.jabatanId._id);
        setStatusAlamat(data.alamat.statusAlamat);
        setStatusRekening(data.rekening.statusRekening);
        setStatusAsuransi(data.asuransi.statusAsuransi);
      },
    }
  );

  const { data: dataPerusahaan, isLoading: isLoadingPerusahaan } = useSWR(
    `${API_URL}/admin/perusahaan-select`,
    sendRequestGet
  );

  const { data: dataJabatan, isLoading: isLoadingJabatan } = useSWR(
    `${API_URL}/admin/jabatan-select`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/admin/karyawan/${id}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        nik: nik,
        namaLengkap: namaLengkap,
        tmpLahir: tmpLahir,
        tglLahir: tglLahir,
        jenisKel: jenisKel,
        nomorHp: nomorHp,
        nomorKaryawan: nomorKaryawan,
        perusahaanId: perusahaanId,
        jabatanId: jabatanId,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating || isLoading || isLoadingPerusahaan || isLoadingJabatan)
    return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Edit Karyawan
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Perubahan data karyawan, isi informasi data dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6 gap-3">
        <form
          onSubmit={simpan}
          className="col-start-1 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">Detail Karyawan</h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Nama jabatan, status aktif
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="NOMOR INDUK KEPENDUDUKAN"
                name="nik"
                val={nik}
                set={setNik}
                placeholder="Masukkan nik"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="NOMOR KARYAWAN"
                name="noKaryawan"
                val={nomorKaryawan}
                set={setNomorKaryawan}
                placeholder="Masukkan nomor karyawan"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-4">
              <InputText
                label="TEMPAT LAHIR"
                name="tmpLahir"
                val={tmpLahir}
                set={setTmpLahir}
                placeholder="Masukkan tempat lahir"
              />
            </div>
            <div className="sm:col-span-2">
              <InputTgl
                label="TANGGAL LAHIR"
                name="tglLahir"
                val={tglLahir}
                set={setTglLahir}
                placeholder="99/99/9999"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="NAMA LENGKAP"
                name="namaLengkap"
                val={namaLengkap}
                set={setNamaLengkap}
                placeholder="Masukkan nama lengkap"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-3">
              <InputText
                label="NOMOR HP"
                name="noHp"
                val={nomorHp}
                set={setNomorHp}
              />
            </div>
            <div className="sm:col-span-3">
              <Select
                label="JENIS KELAMIN"
                name="jenisKel"
                val={jenisKel}
                set={setJenisKel}
                item={itemTipe}
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <Select
                label="PERUSAHAAN BEKERJA"
                name="perusahaanId"
                val={perusahaanId}
                set={setPerusahaanId}
                item={dataPerusahaan}
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <Select
                label="JABATAN DIPERUSAHAAN BEKERJA"
                name="jabatanId"
                val={jabatanId}
                set={setJabatanId}
                item={dataJabatan}
              />
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50 focus:outline-none focus:ring-[#3b5998]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave />
              </span>
              <span>Update karyawan</span>
            </button>
          </div>
        </form>
        <div className="col-span-2 py-5 shadow-xl rounded-2xl bg-white">
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">
              Status Data Karyawan
            </h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Status kelengkapan data karyawan
            </p>
          </div>
          <div className="p-4">
            <ul className="font-sans font-sm text-sm divide-y divide-gray-200 divide-dashed">
              <li>
                <div className="flex flex-row items-center py-2 hover:bg-gray-100">
                  {statusAlamat === "Y" ? (
                    <HiCheckCircle size={20} color="#4ade80" />
                  ) : (
                    <HiXCircle size={20} color="#f43f5e" />
                  )}

                  <p className="flex-1 ml-2">Kelengkapan data profile</p>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center py-2 hover:bg-gray-100">
                  {statusRekening === "Y" ? (
                    <HiCheckCircle size={20} color="#4ade80" />
                  ) : (
                    <HiXCircle size={20} color="#f43f5e" />
                  )}
                  <p className="flex-1 ml-2">Kelengkapan data BANK</p>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center py-2 hover:bg-gray-100">
                  {statusAsuransi === "Y" ? (
                    <HiCheckCircle size={20} color="#4ade80" />
                  ) : (
                    <HiXCircle size={20} color="#f43f5e" />
                  )}
                  <p className="flex-1 ml-2">Kelengkapan data Asuransi</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEdit;
